import React, { useState } from "react";
import { Card, Typography, Box, Avatar, Divider, Button, IconButton, Tooltip, Link, Chip } from "@mui/material";
import { Phone, MailOutline, LocationOn, WhatsApp, Edit, Block, Diversity3, Description } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { i18n } from "../../translate/i18n";
import hexToRGBA from "../../utils/hexToRGBA";
import MarkdownWrapper from "../MarkdownWrapper";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import api from "../../services/api";
import GavelIcon from '@mui/icons-material/Gavel';
import toastError from "../../errors/toastError";
import ToastError from "../../toast/error/toastError";

const ContactCard = ({ contact, gestor, modalOpen = false, setModalOpen }) => {
    const [loading, setLoading] = useState(false);

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '400px',
        minWidth: '400px',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        transition: '0.3s',
        ':hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
        },
    };

    const avatarStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        background: (theme) => hexToRGBA(theme.palette.primary.main, 0.7),
        '@media (max-width: 600px)': {
            width: '100%',
            height: 'auto',
        },
    };

    const avatarPicStyle = {
        width: '120px',
        height: '120px',
        border: '4px solid white',
    };

    const infoStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '24px',
        flex: 2,
    };

    const infoBoxStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
    };

    const iconStyle = {
        marginRight: '8px',
        color: 'primary.main',
    };

    const buttonStyle = {
        width: '48%',
        borderRadius: '8px',
    };

    const blockButtonStyle = {
        backgroundColor: contact.isBlocked ? '#f44336' : '#4caf50',
        color: 'white',
        ':hover': {
            backgroundColor: contact.isBlocked ? '#e53935' : '#43a047',
        },
    };

    const formatNumber = (telNumber) => telNumber.replace(/[^0-9]/g, '');

    const handleBlockContact = async () => {
        setLoading(true);
        try {
            await api.put(`/contacts/${contact.id}`, {
                isBlocked: !contact.isBlocked,
                name: contact.name,
                number: formatNumber(contact.number),
            });
            setLoading(false);
        } catch (err) {
            ToastError(err);
            setLoading(false);
        }
    };

    const isLink = (desc) => {
        return desc.startsWith('http://') || desc.startsWith('https://');
    };

    return (
        <Card style={cardStyle} sx={{ background: (theme) => hexToRGBA(theme.palette.mode == "dark" ? "#212B36" : "#f7f7f7", theme.palette.mode == "dark" ? 0.7 : 0.2) }}>
            <Box style={avatarStyle}>
                <Avatar src={contact.profilePicUrl} alt="contact_image" style={avatarPicStyle} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h6" component="div" gutterBottom>
                    {contact?.name || ""}
                </Typography>
            </Box>
            {(gestor && gestor?.DescricaoRota) && <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="caption" component="div" gutterBottom>
                    {gestor?.DescricaoRota && `${gestor.DescricaoRota}`}
                </Typography>
            </Box>}
            <Divider>
                <Chip size="small" label="Informações do contato" sx={{ borderRadius: '2px' }} />
            </Divider>
            <Box style={infoStyle}>

                {contact.contract && (
                    <Box style={infoBoxStyle}>
                        <GavelIcon style={iconStyle} />
                        <Typography variant="body2">Contrato: {contact.contract}</Typography>
                    </Box>
                )}

                {gestor?.ContatoTelefone?.length > 0 && (
                    <Box style={infoBoxStyle}>
                        <PersonPinIcon style={iconStyle} />
                        <Typography variant="body2">Tel. Responsável: {gestor.ContatoTelefone[0]?.Numero}</Typography>
                    </Box>
                )}

                <Box style={infoBoxStyle}>
                    {contact.isGroup ? <Diversity3 style={iconStyle} /> : <Phone style={iconStyle} />}
                    <Typography variant="body2">{contact.number}</Typography>
                </Box>

                {contact.email && (
                    <Box style={infoBoxStyle}>
                        <MailOutline style={iconStyle} />
                        <Typography variant="body2">{contact.email}</Typography>
                    </Box>
                )}

                {contact.location && (
                    <Box style={infoBoxStyle}>
                        <LocationOn style={iconStyle} />
                        <Typography variant="body2"><MarkdownWrapper>{contact.location}</MarkdownWrapper></Typography>
                    </Box>
                )}

                {contact.description && (
                    <Box style={infoBoxStyle}>
                        <Description style={iconStyle} />
                        <Typography variant="body2">
                            {contact?.description && <>
                                {isLink(contact.description) ? (
                                    <a target="_blank" rel="noopener noreferrer" href={contact.description}>
                                        <MarkdownWrapper>{contact.description}</MarkdownWrapper>
                                    </a>
                                ) : (
                                    <MarkdownWrapper>{contact.description}</MarkdownWrapper>
                                )}
                            </>}
                        </Typography>
                    </Box>
                )}

                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button onClick={() => setModalOpen(true)} variant="contained" size="small" color="secondary" sx={buttonStyle} startIcon={<Edit />}>
                        Editar
                    </Button>
                    <LoadingButton
                        variant="contained" size="small" onClick={() => handleBlockContact()} sx={{ ...buttonStyle, ...blockButtonStyle }} loading={loading}
                    >
                        {contact.isBlocked ? 'Desbloquear' : 'Bloquear'}
                    </LoadingButton>
                </Box>

                {contact?.extraInfo && contact?.extraInfo.length > 0 && (
                    <>
                        <Divider sx={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Chip size="small" label="Adicionais" sx={{ borderRadius: '2px' }} />
                        </Divider>
                        {contact?.extraInfo.map((info) => (
                            <Box key={info.name}>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>{info.name}:</Typography>
                                <Typography variant="subtitle1" gutterBottom>{info.value}</Typography>
                            </Box>
                        ))}
                    </>
                )}
            </Box>
        </Card>
    );
};

export default ContactCard;
